import React, { useRef } from 'react'
import styles from '@src/styles/card.module.scss'

interface Propp {
  video:string
}

const HomepageVideoDiv: React.FC<Propp> = ({video}) => {

  const vidRef = useRef<HTMLVideoElement|null>(null)  as React.MutableRefObject<HTMLVideoElement>
  // const [isShown, setIsShown] = useState(false)
  // useEffect(() => {
  //   const handleScroll = () => {
  //     if(/* !isShown && */ window.scrollY>800) {
  //       // setIsShown(true)
  //       console.log('Play video')
  //       void vidRef.current.play()
  //       window.removeEventListener('scroll', handleScroll)
  //     }
  //   }
  //
  //   handleScroll()
  //
  //   window.addEventListener('scroll', handleScroll)
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll)
  //   }
  //
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // const texts = GenerateCardTexts(card)

  return (
    <div
      className={`${styles.videoCardContainer}`}
      >
      {/* video */}
      {/* <div className={`${styles.videoPart} rounded-2xl border-4 overflow-hidden my-16`}> */}
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video ref={vidRef} className='rounded-3xl' playsInline autoPlay loop muted>
          <source src={video} type="video/mp4" />
        </video>
      {/* </div> */}
      {/* <div className="flex-0.5"/> */}
      {/* texts */}
      {/* <div className={styles.videoTextPart}> */}
      {/*  {texts} */}
      {/* </div> */}
    </div>
  )
}

export default HomepageVideoDiv