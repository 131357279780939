import React, { useEffect } from 'react'
import { HomepageData, TextData } from '@src/interfaces/homepage'
import HomepageTextDiv from '@src/components/homepageTextDiv'
import HomepageVideoDiv from '@src/components/homepageVideoDiv'
import { NextPage } from 'next'

import { CookiesModal, SubscribeModal } from '@src/pages/_app'
import Head from 'next/head'
import ResponsiveTwoCardsParent from '@src/components/ResponsiveTwoCardsParent'
import HorizontalImageCard from '@src/components/HorizontalImageCard'
import ImageCardProps, { HorizontalImageCardProps } from '@src/interfaces/card'
import VerticalImageCard from '@src/components/VerticalImageCard'
import ScreenSizeSwitchCard from '@src/components/ScreenSizeSwitchCard'
import useTranslation from '@src/hooks/i18n'
import styles from '../styles/test.module.scss'

interface Props {
  homepageData: HomepageData
}

const HomePage: NextPage<Props> = ({ homepageData }: Props) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      {/*@ts-ignore */ }
      if (typeof window.gtag !== 'undefined') {
        {/*@ts-ignore */ }
        window.gtag('event', `web_view_home_page`)
      }
    }
  }, [])
  // Hero animation
  // const { scrollYProgress } = useViewportScroll()
  // const scale = useTransform(scrollYProgress, [0, 1], [1, 4])
  const translator = useTranslation().home
  const card1Data: ImageCardProps = {
    title: translator.bankingAsAService.title,
    description: translator.bankingAsAService.description,
    image: '/baas.png',
    cardColor: '#6DBFB8',
    button: translator.bankingAsAService.button,
    clickAction: () => {
      {/*@ts-ignore */ }
      window.gtag('event', `web_click_explore_banking`)
      window.location.href = '/business?p=1'
    }
  }
  const card1DesktopData: HorizontalImageCardProps = {
    ...card1Data,
    parentStyle: { flexDirection: 'row', minHeight: '500px' },
    imageClass: 'h-[min(25vw,300px)] my-auto'
  }

  const card2Data: TextData = {
    title: translator.colendiServices.title,
    header: translator.colendiServices.header,
    description: translator.colendiServices.description,
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const card3_1Data: ImageCardProps = {
    title: translator.scoring.title,
    description: translator.scoring.description,
    image: '/scoring.png',
    imageMobile: '/scoringMobile.png',
    button: translator.scoring.button,
    clickAction: () => {
      {/*@ts-ignore */ }
      window.gtag('event', `web_click_discover_scoring`)
      window.location.href = '/business?p=3'
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const card3_2Data: ImageCardProps = {
    title: translator.microCredit.title,
    description: translator.microCredit.description,
    image: '/microcredit.png',
    imageMobile: '/microcreditMobile.png',
    button: translator.microCredit.button,
    clickAction: () => {
      {/*@ts-ignore */ }
      window.gtag('event', `web_click_micro_credit`)
      window.location.href = '/business?p=4'
    }
  }

  const card4Data: HorizontalImageCardProps = {
    title: translator.buyNowPayLater.title,
    description: translator.buyNowPayLater.description,
    cardColor: 'rgb(249, 216, 119)',
    image: '/buynowpaylater.png',
    imageClass: 'h-[min(40vh,500px)] mx-auto',
    button: translator.buyNowPayLater.button,
    clickAction: () => {
      {/*@ts-ignore */ }
      window.gtag('event', `web_click_bnpl`)
      window.location.href = '/business?p=5'
    }
  }
  const card4DesktopData: HorizontalImageCardProps = {
    ...card4Data,
    parentStyle: { flexDirection: 'row-reverse' },
    imageClass: 'translate-y-[40px] h-[min(60vh,500px)]'
  }

  const card5Data: HorizontalImageCardProps = {
    title: translator.invest.title,
    description: translator.invest.description,
    image: '/invest.png',
    imageClass: 'h-[min(40vh,500px)] mx-auto',
    button: translator.invest.button,
    clickAction: () => {
      {/*@ts-ignore */ }
      window.gtag('event', `web_click_start_investing`)
      window.location.href = '/personal?p=4'
    }
  }
  const card5DesktopData: HorizontalImageCardProps = {
    ...card5Data,
    parentStyle: { flexDirection: 'row', overflow: 'hidden', maxHeight: '500px' },
    imageClass: 'translate-y-[70px] h-[min(80vw,600px)] px-8'
  }

  return (
    <>
      <div className={styles.homepage}>
        <Head>
          <title>Colendi</title>
        </Head>
        <div className="absolute overflow-hidden w-full h-auto">
          <div
            className="w-full h-auto relative">
            {homepageData &&
              <img id="heroImg" src="/heroBlank.png" alt="heroBlank" />
            }
          </div>
        </div>
        <div className={styles.hero}>
          <div className="block {/*md:block*/} w-full flex flex-col justify-center z-10 md:-mt-10">
            <div className={styles.heroContainer}>
              <div className="flex max-w-[600px] md:max-w-[1168px] flex-col lg:flex-row px-[20px] w-full">
                <div className="flex justify-center max-w-[570px] pt-8 lg:pt-0">
                  <video playsInline autoPlay loop muted>
                    <source src="/homepageVideo.mp4" type="video/mp4" />
                  </video>
                </div>
                <div className="flex flex-col lg:self-center">
                  <h1 className={styles.homepageHeroTitle} dangerouslySetInnerHTML={{ __html: translator.heroTitle }} />
                  <h2 className={styles.homepageHeroDescription}
                    dangerouslySetInnerHTML={{ __html: homepageData?.heroDesc }} />
                  <div className={styles.agreeText}>{homepageData?.heroFooter1}{' '}<span role='button' className={styles.agreeLink} onClick={() => {
                    window.open('/Colendi_Privacy_Policy.pdf', '_blank')
                  }}>{homepageData?.heroFooter2}</span></div>
                  <div className={styles.subscribeContainer}>
                    <SubscribeModal buttonText={translator.subscribeButton} />
                  </div>
                  <CookiesModal />
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className={styles.homepageMainSpace}>

          <ScreenSizeSwitchCard
            mobileComponent={<VerticalImageCard props={card1Data} />}
            desktopComponent={<HorizontalImageCard props={card1DesktopData} />}
          />

          <HomepageTextDiv data={card2Data} />

          <ResponsiveTwoCardsParent items={
            [
              card3_1Data,
              card3_2Data
            ]
          } />

          <ScreenSizeSwitchCard
            mobileComponent={<VerticalImageCard props={card4Data} />}
            desktopComponent={<HorizontalImageCard props={card4DesktopData} />}
          />

          <ScreenSizeSwitchCard
            mobileComponent={<VerticalImageCard props={card5Data} />}
            desktopComponent={<HorizontalImageCard props={card5DesktopData} />}
          />

          <div className="mt-12 lg:mt-16" />

          <HomepageVideoDiv video="/video.mp4" />

          <div className="h-24" />

        </div>

      </div>
    </>
  )
}

export default HomePage
