import berkStyles from '@src/styles/berk.module.scss'
import ImageCardProps from '@src/interfaces/card'
import React from 'react'
import VerticalImageCard from '@src/components/VerticalImageCard'

interface IProps {
  items: ImageCardProps[]
}

const ResponsiveTwoCardsParent: React.FC<IProps> = ({ items }) => {
  return (
    <div className={berkStyles.twoCardsParent}>
      {
        items.map((child) => {
          return <VerticalImageCard props={child} />
        })
      }
    </div>
  )
}

export default ResponsiveTwoCardsParent