import { useRouter } from 'next/router'
import { PageQuery } from '@src/interfaces/pagequery'

export interface Languages {
  languages: Language[]
}

export interface Language {
  code: string
  name: string
}

interface GFILprops {
  fileEN: any
  fileTR: any
  language?: string
}

export const GetFileInLanguage = (props: GFILprops) => {
  const router = useRouter()
  const pageQuery: PageQuery = router.query

  const lang = pageQuery.l ?? props.language

  if (lang !== undefined) {
    if (lang.startsWith('tr')) {
      return props.fileTR
    }
    if (lang.startsWith('de')) {
      // Test purposes
      return props.fileTR
    }
  }

  return props.fileEN
}

export const setLanguage=(code:string) => {
  // sessionStorage.setItem()
}