import styles from '@src/styles/card.module.scss'
import React, { CSSProperties } from 'react'
import { TextData } from '@src/interfaces/homepage'

interface IProps{
  data:TextData
}

const HomepageTextDiv: React.FC<IProps> = ({data}) => {

  const titleStyle:CSSProperties= { }
  if (data.titleSize!==undefined) {
    titleStyle.fontSize=data.titleSize
  }
  if (data.titleHeight!==undefined) {
    titleStyle.lineHeight=data.titleHeight
  }

  const descStyle:CSSProperties= { }
  if (data.descriptionSize!==undefined) {
    descStyle.fontSize=data.descriptionSize
  }

  const containerStyle:CSSProperties= {
    paddingTop:'0rem'
  }
  if (data.topPadding!==undefined) {
    containerStyle.paddingTop=data.topPadding
  }

  return (
    <div className="flex flex-col" style={containerStyle}>
      <div className={styles.grayHeader}>
        <span>{data.header}</span>
      </div>
      {data.title.length > 0 &&
        <div className={styles.blackBoldTitle} style={titleStyle}>
          <span style={titleStyle} dangerouslySetInnerHTML={{ __html: data.title }}></span>
        </div>
      }
      {data.description &&
        <div className={styles.blackishDescription}>
          <span style={descStyle} dangerouslySetInnerHTML={{ __html: data.description }} />
        </div>
      }
    </div>
  )
}

export default HomepageTextDiv