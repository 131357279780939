import berkStyles from '@src/styles/berk.module.scss'
import { ChevronRightIcon } from '@heroicons/react/outline'
import React, { CSSProperties } from 'react'
import { HorizontalImageCardProps } from '@src/interfaces/card'

interface IProps{
  props:HorizontalImageCardProps
}

const HorizontalImageCard: React.FC<IProps> = ({props}) => {

  const TitleStyle:CSSProperties={}
  if (props.titleColor){
    TitleStyle.color=props.titleColor
  }
  const DescriptionStyle:CSSProperties={}
  if (props.descriptionColor){
    DescriptionStyle.color=props.descriptionColor
  }
  const ClickTextStyle: CSSProperties = {}
  if (props.clickLinkColor) {
    ClickTextStyle.color = props.clickLinkColor
  }

  let ParentStyle: CSSProperties = {}
  if (props.parentStyle) {
    ParentStyle = props.parentStyle
  }
  if (props.cardColor) {
    ParentStyle = { ...ParentStyle, backgroundColor: props.cardColor }
  }

  function handleClick() {
    if (props.clickAction) {
      props.clickAction()
    }
  }

  return (
    // eslint-disable-next-line jsx-a11y/aria-role
    <div role={`${props.clickAction ? 'button' : ''}`} onClick={handleClick} className={`${berkStyles.horizontalImageCard}`} style={ParentStyle}>
      <img className={`${berkStyles.cardImage} ${props.imageClass ?? ''}`} src={props.image} alt={props.image.replaceAll('/', '')} />
      <div className={props.button ? berkStyles.horizontalImageCardTextDivWithClickText : berkStyles.horizontalImageCardTextDiv}>
        <div>
          <p className={berkStyles.cardTitle} style={TitleStyle} dangerouslySetInnerHTML={{ __html: props.title }} />
          <p className={berkStyles.cardDescription} style={DescriptionStyle} dangerouslySetInnerHTML={{ __html: props.description }} />
        </div>
        {props.button &&
          <div className="flex">
            <div className={berkStyles.cardLinkContainer} style={ClickTextStyle}>
              <span>{props.button}</span>
              <ChevronRightIcon className={berkStyles.cardLinkIcon} aria-hidden="true" />
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default HorizontalImageCard
