import React from 'react'
import { useMediaQuery } from '@mui/material'

interface IProps {
  mobileComponent: JSX.Element
  desktopComponent: JSX.Element
}

const ScreenSizeSwitchCard: React.FC<IProps> = ({ mobileComponent, desktopComponent }) => {
  const isDesktop = useMediaQuery('(min-width: 768px)')

  return (
    <>
      {!isDesktop && mobileComponent}
      {isDesktop && desktopComponent}
    </>

  )
}

export default ScreenSizeSwitchCard