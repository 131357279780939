import berkStyles from '@src/styles/berk.module.scss'
import { ChevronRightIcon } from '@heroicons/react/outline'
import React, { CSSProperties } from 'react'
import ImageCardProps from '@src/interfaces/card'
import { useMediaQuery } from '@mui/material'

interface IProps {
  props: ImageCardProps
}

const VerticalImageCard: React.FC<IProps> = ({ props }) => {
  const imageAlt = props.image.replaceAll('/', '')

  const isDesktop = useMediaQuery('(min-width: 768px)')

  let cardStyle: CSSProperties = {}
  if (props.parentStyle) {
    cardStyle = props.parentStyle
  }
  if (props.cardColor) {
    cardStyle.backgroundColor = props.cardColor
  }

  const TitleStyle: CSSProperties = {}
  if (props.titleColor) {
    TitleStyle.color = props.titleColor
  }
  const DescriptionStyle: CSSProperties = {}
  if (props.descriptionColor) {
    DescriptionStyle.color = props.descriptionColor
  }
  const ClickTextStyle: CSSProperties = {}
  if (props.clickLinkColor) {
    ClickTextStyle.color = props.clickLinkColor
  }

  return (
    <div role={`${props.clickAction?'button':'div'}`} onClick={(e)=>{
      if (props.clickAction) {
        props.clickAction()
      }
    }} className={berkStyles.twoCardsChild} style={cardStyle} >
      <div>
        <p className={berkStyles.cardTitle} style={TitleStyle} dangerouslySetInnerHTML={{ __html: props.title }} />
        <p className={berkStyles.cardDescription} style={DescriptionStyle} dangerouslySetInnerHTML={{ __html: props.description }} />
      </div>
      <div>
        {props.imageMobile === undefined &&
          <img className={`${berkStyles.cardImage} ${props.imageClass ?? ''}`} src={props.image} alt={imageAlt} />
        }
        {props.imageMobile &&
          <>
            {!isDesktop &&
              <img className={berkStyles.cardImage} src={props.imageMobile} alt={imageAlt} />
            }
            {isDesktop &&
              <img className={berkStyles.cardImage} src={props.image} alt={imageAlt} />
            }
          </>
        }
        {props.button &&
          <div className="flex">
            <div className={berkStyles.cardLinkContainer} style={ClickTextStyle}>
              <span>{props.button}</span>
              <ChevronRightIcon className={berkStyles.cardLinkIcon} aria-hidden="true" />
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default VerticalImageCard
