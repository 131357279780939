import React from 'react'
import HomePage from '@src/components/homepage'
import { HomepageData } from '@src/interfaces/homepage'
import { GetFileInLanguage } from '@src/interfaces/language'
import { NextPage } from 'next'

const homepageDataEN: HomepageData = require('@public/homepagedataEN.json')
const homepageDataTR: HomepageData = require('@public/homepagedataTR.json')

const Home:NextPage = () => {

  let element: any | undefined

  if (typeof window !== 'undefined') {
    console.log(navigator.language)
    const homepageData: HomepageData = GetFileInLanguage({
      fileEN: homepageDataEN,
      fileTR: homepageDataTR,
      language: navigator.language
    })
    element = <HomePage homepageData={homepageData} />
  } else {
    element = <HomePage homepageData={homepageDataEN} />
  }

// const Home: NextPage = () => {
  return (
    <>
      {element}
    </>
    // <HomePage homepageData={homepageData} />
  )
}

export default Home